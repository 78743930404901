import React from "react";
import { Link } from "react-router-dom";

import "./Header.css";
import logo from "../images/logo.png";

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <nav className="nav">
        <Link to="/">Home</Link>
        <Link to="/about">About Us</Link>
        <Link to="/features">Features</Link>
        <Link to="/clients">Clients</Link>
        <Link to="/interns">Interns</Link>
      </nav>
      <div className="divider"></div>
      <div className="auth-buttons">
        <button className="login">Log In</button>
        <button className="getstarted">Get Started</button>
      </div>
    </header>
  );
};

export default Header;
