import React from "react";

import vivianPic from "../images/intern-pic-vivian.jpg";
import punyaPic from "../images/intern-pic-punya.jpg";
import "./Interns.css";

const Profile = ({ imgSrc, name, position, info }) => {
  return (
    <>
      <div className="vertical-align">
        <img src={imgSrc} alt={`${name} Picture`} />
        <body className="large-caption">{name}</body>
        <body className="caption">{position}</body>
        <body>{info}</body>
      </div>
    </>
  );
};

const Interns = () => {
  return (
    <>
      <h1 className="center">Interns</h1>
      <p className="text-container">
        Are you a high achieving student in highschool? Well then you’re at the
        right place. As a Bookin intern, you will be able to gain valuable
        skills in software development, graphic designing, and communication. We
        will provide you with skills that will help you be successful throughout
        your life. Let’s make reservations easier than ever before together!
        Show your interest with this{" "}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSd7qzPfCe0cJKhh4cs3PEXQHTN2BAoY4oOAviiXNikntfg7ZQ/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>link</u>
        </a>
        .
      </p>
      <div className="pics">
        <Profile
          imgSrc={vivianPic}
          name="Vivian Vuong"
          position="Graphic Design Intern"
          info="Senior at Eden Prairie High School"
        ></Profile>
        <Profile
          imgSrc={punyaPic}
          name="Punya Pavuluri"
          position="Front End Software intern"
          info="Junior at Eden Prairie High School"
        ></Profile>
      </div>
    </>
  );
};

export default Interns;
