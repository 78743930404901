import React from "react";

import launchPic from "../images/bookin-launch-pic.png";
import "./Landing-Home.css";

const Landing = () => {
  return (
    <>
      <div className="horizontal-align">
        <div className="container">
          <img src={launchPic}></img>
        </div>
        <div className="vertical-align">
          <h1>Bookin</h1>
          <h3>Reserving spaces has never been easier.</h3>
          <div className="auth-buttons">
            <button className="getstarted">Get Started</button>
            <button className="login">Log In</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
