import React from "react";

import darrenPic from "../images/founder-pic-darren.jpeg";
import nihalPic from "../images/founder-pic-nihal.jpg";
import "./About.css";

const Profile = ({ imgSrc, name, position, info }) => {
  return (
    <>
      <div className="vertical-align">
        <img src={imgSrc} alt={`${name} Picture`} />
        <body className="large-caption">{name}</body>
        <body className="caption">{position}</body>
        <body>{info}</body>
      </div>
    </>
  );
};

const About = () => {
  return (
    <>
      <h1 className="center">About Us</h1>
      <body className="center">
        Our mission is to make reservations simple through user-friendly
        services.
      </body>
      <div className="pics">
        <Profile
          imgSrc={darrenPic}
          name="Darren Nguyen"
          position="Co-Founder"
          info="Senior at Eden Prairie High School"
        ></Profile>
        <Profile
          imgSrc={nihalPic}
          name="Nihal Kacham"
          position="Co-Founder"
          info="Senior at Eden Prairie High School"
        ></Profile>
      </div>
      <p className="text-container">
        We started Bookin during our time in the iOS mobile app development
        class at Eden Prairie High School. Our teacher took us around the
        departments to propose solutions, and we identified the Activities
        Office as our focus. Seeing how complex and unnecessarily difficult the
        system to request spaces at the school was, we decided to create Bookin.
      </p>
    </>
  );
};

export default About;
