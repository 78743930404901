import React from "react";
import { Routes, Route } from "react-router-dom";

import Landing from "./pages/Landing-Home";
import About from "./pages/About";
import Interns from "./pages/Interns";
import Features from "./pages/Features";
import Clients from "./pages/Clients";
import Header from "./components/Header";
import "./App.css";

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Features />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/interns" element={<Interns />} />
      </Routes>
    </>
  );
};

export default App;
