import React, { useState } from "react";

import reservePic from "../images/bookin-reserve-pic.png";
import homePic from "../images/bookin-home-pic.png";
import adminPic from "../images/bookin-admin-pic.png";
import "./Features.css";

const Features = () => {
  const [featureText, setFeatureText] = useState("Select a Screen");
  const [bodyText, setBodyText] = useState("");

  const handleReserveClick = () => {
    setFeatureText("Reserve");
    setBodyText(`
      The Reservation page includes the times, dates, locations, and supplies renters can request. 
      Additionally, Bookin automatically detects conflicts with current reservations and blocks overlapping events. 
      With customized price structures, renters receive estimated costs per reservation.`);
  };

  const handleAdminClick = () => {
    setFeatureText("Admin");
    setBodyText(`
      Administrators can easily approve or deny reservations on the Requests page. 
      If they need more information, admins can click on the info button to receive 
      a detailed breakdown including times, dates, locations, supplies needed, and more.
`);
  };

  const handleHomeClick = () => {
    setFeatureText("Home");
    setBodyText(`
      On the Home page, renters can view all of their approved, pending, or rejected requests. 
      Bookin also offers a calendar that displays approved and upcoming reservations, allowing 
      for easy organization and schedule management.`);
  };
  return (
    <>
      <h1 className="center">Features</h1>
      <h2 className="center">{featureText}</h2>
      <body className="text-container">{bodyText}</body>
      <div className="demos">
        <button className="button">
          <img
            src={reservePic}
            alt="Demo Pic Reserve"
            onClick={handleReserveClick}
          />
        </button>
        <button className="button">
          <img src={adminPic} alt="Demo Pic Admin" onClick={handleAdminClick} />
        </button>
        <button className="button">
          <img src={homePic} alt="Demo Pic Home" onClick={handleHomeClick} />
        </button>
      </div>
    </>
  );
};

export default Features;
